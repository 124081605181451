<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	import 'lib-flexible'
	import Cookies from 'js-cookie'
	export default {
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {

			if (Cookies.get('token', {
					domain: '.tofobao.com'
				})) {
				sessionStorage.setItem('token', JSON.stringify(JSON.parse(Cookies.get('token', {
					domain: '.tofobao.com'
				}))))
			} else {
				sessionStorage.removeItem('token')
			}

		},

		methods: {

		}
	}
</script>

<style>
	@import './assets/css/bootstrap.css';

	*,
	html,
	body,
	p {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: '微软雅黑';
	}

	/* 	::selection {
		background: #059fa5;
		color: #fff;
	} */

	::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 4px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 4px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px #ffffff;
		box-shadow: inset 0 0 5px #ffffff;
		background: #999999;
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		-webkit-box-shadow: inset 0 0 5px #ffffff;
		box-shadow: inset 0 0 5px #ffffff;
		border-radius: 10px;
		background: #dddddd;
	}
</style>
