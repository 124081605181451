import axios from 'axios';
import {
	MessageBox,
	Message
} from 'element-ui'

// import router from './router'
// axios 配置
var instance = axios.create({
	// headers: {
	// 	'Content-Type': 'application/json',
	// },
	timeout: 30000,
	baseURL: process.env.VUE_APP_URL_NEW, //接口请求地址
})
// instance.defaults.headers.common["Authorization"] = ''
// instance.defaults.headers.post["Content-Type"] = "application/json";
instance.interceptors.request.use(config => {
	if (JSON.parse(sessionStorage.getItem('userdata')) == null) {
		config.data.activeUserId = ''
	} else {
		if(config.method == 'get'){
			if(config.url.indexOf("?") == -1){
				config.url = config.url+'?activeUserId='+JSON.parse(sessionStorage.getItem('userdata')).id
			}else{
				config.url = config.url+'&activeUserId='+JSON.parse(sessionStorage.getItem('userdata')).id
			}
		}else if(config.method == 'post'){
			if(config.data){
				config.data.activeUserId = JSON.parse(sessionStorage.getItem('userdata')).id
			}else{
				config.data = {
					activeUserId:JSON.parse(sessionStorage.getItem('userdata')).id
				}
			}
		}
	}
	return config
})
//拦截重复请求
// let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
// let cancelToken = axios.CancelToken;
// let removeRepeatUrl = (config) => {
// 	let comValue = config.method == 'get' ? qs.stringify(config.params) : qs.stringify(config.data);
// 	for (let p in pending) {
// 		if (pending[p].u === config.url + '&' + config.method + '&' + comValue) { //当前请求在数组中存在时执行函数体
// 			pending[p].f(); //执行取消操作
// 			pending.splice(p, 1); //把这条记录从数组中移除
// 		}
// 	}
// }
// let saveRepeatUrl = (config) => {
// 	let comValue = config.method == 'get' ? qs.stringify(config.params) : qs.stringify(config.data);
// 	console.log(comValue)
// 	config.cancelToken = new cancelToken((c) => {
// 		pending.push({
// 			u: config.url + '&' + config.method + '&' + comValue,
// 			f: c
// 		}); // 自定义唯一标识
// 	});
// }


// 添加请求拦截器
// instance.interceptors.request.use(config => {
// 	// 在发送请求之前做些什么，比如传token
// 	removeRepeatUrl(config); //在一个ajax发送前执行一下取消操作
// 	saveRepeatUrl(config); //保存请求地址
// 	return config
// }, error => {
// 	// 对请求错误做些什么
// 	console.log(error) // for debug
// 	return Promise.reject(error);
// })


// 添加响应拦截器
instance.interceptors.response.use(response => {
	// removeRepeatUrl(response.config); //执行取消操作，把已经完成的请求从pending中移除
	// 对响应数据做点什么
	const res = response.data;
	if(res.status == 200){
		return res;
	}else{
		Message({
			message: res.msg,
			type: 'info'
		})
		return response;
	}
	//对错误代码做处理
	
}, error => {
	// 对响应错误做点什么
	console.log('err' + error) // for debug
	return Promise.reject(error);
});


export default instance;

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
	return new Promise((resolve, reject) => {
		instance.post(url, data).then(response => {
			//对接口错误码做处理
			resolve(response.data);
		}, err => {
			reject(err);
		})
	})
}

/**
 * get 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, data = {}) {
	return new Promise((resolve, reject) => {
		instance.get(url, {
				params: data
			})
			.then(response => {
				resolve(response);
			})
			.catch(err => {
				reject(err)
			})
	})
}

/**
 * 封装所有请求
 * @param methed
 * @param url
 * @param data 
 * @param headers
 * @returns {Promise}
 */
export function request(methed, url, data = {}, headers) {
	return new Promise((resolve, reject) => {
		instance({
				method: methed || 'post',
				url: url,
				params: methed === 'get' ? data : '',
				data: methed !== 'get' ? data : '',
				headers: headers || {
					'Content-Type': 'application/json'
				},
			})
			.then(response => {
				//对接口错误码做处理
				resolve(response.data);
			})
			.catch(err => {
				reject(err)
			})
	})
}



// 默认配置
// import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// let host = "";

// if (process.env.NODE_ENV === 'development') {	//连接的服务器
//   // host = 'http://192.168.3.95:8088';
//   host = "http://121.196.184.146:8088"; //线上
//   // host = 'http://192.168.3.93:8088';
//   // host = 'http://192.168.3.110:8088';
// } else {
//   host = "http://121.196.184.146:8088";
// }

// const request = axios.create({	//axios异步请求
//   baseURL: host, // url = base url + request url
//   timeout: 30000 // request timeout
// })
// // request interceptor
// request.interceptors.request.use(	//请求钱拦截器
//   config => {
//     if (store.getters.token) {
//       config.headers['Auth-Token'] = getToken()
//     }
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

// // response interceptor
// request.interceptors.response.use(	//请求后拦截器
//   response => {
//     const res = response;

//     if (res.status == 200 && res.data.result_code == -101) {
//       Message({
//         message: '网络连接失败，请重新登录',
//         type: 'error',
//         duration: 1800
//       });
//       setTimeout(function () {
//         window.sessionStorage.clear();
//         window.localStorage.clear();
//         window.location.href = "/"
//       }, 2000);
//       return;
//     }
//     if (response.status == 200) {
//       return Promise.resolve(response);
//     }

//     // if the custom code is not 20000, it is judged as an error.
//     if (res.code !== 20000) {
//       Message({
//         message: res.message || 'Error',
//         type: 'error',
//         duration: 1800
//       })

//       // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
//       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
//         // to re-login
//         MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
//           confirmButtonText: 'Re-Login',
//           cancelButtonText: 'Cancel',
//           type: 'warning'
//         }).then(() => {
//           store.dispatch('user/resetToken').then(() => {
//             location.reload()
//           })
//         })
//       }
//       return Promise.reject(new Error(res.message || 'Error'))
//     } else {
//       return res
//     }
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

// export {
//   request,
//   host
// }
