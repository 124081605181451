import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from 'store'

Vue.use(VueRouter)


// if (sessionStorage.getItem('token')) {
// 	store.commit('set_token', sessionStorage.getItem('token'))
// }


const routes = [{
		path: "/",
		redirect: "/Home/Login",
	},
	{
		path: '/Login',
		name: 'Login',
		component: () => import( /* webpackChunkName: "about" */ '../views/Login.vue')
	}, {
		path: '/Home',
		name: 'Home',
		component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
		children: [{
			path: '/Home/Meaone',
			name: 'Meaone',
			component: () => import( /* webpackChunkName: "about" */ '../views/meaone/meaone.vue')
		}, ],
	},
	{
		path: '/*',
		name: 'Login',
		component: () => import( /* webpackChunkName: "about" */ '../views/Login.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// router.beforeEach((to, from, next) => {
// 	if (to.matched.some(r => r.meta
// 		.requireAuth)) { //这里的requireAuth为路由中定义的 meta:{requireAuth:true}，意思为：该路由添加该字段，表示进入该路由需要登录的
// 		if (store.state.token) {
// 			next();
// 		} else {
// 			next({
// 				path: '/Login',
// 				query: {
// 					redirect: to.fullPath
// 				}
// 			})
// 		}
// 	} else {
// 		next();
// 	}
// })
// 解决冗余导航
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
// 
export default router
