import Vue from 'vue';


import App from './App.vue';
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
// Vue.config.productionTip = false;

// Vue.use(Antd);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import axios from './utils/api.js'
import axioss from './utils/api_2.js'
import store from "./store";
import Cookies from 'js-cookie'

Vue.prototype.$axios = axios;
Vue.prototype.$axioss = axioss;


Vue.use(ElementUI);
Vue.prototype.dateFormat = function dateFormat(nows, type = 1) {
	var date = new Date(nows * 1000);
	var Y = date.getFullYear();
	var M = date.getMonth() + 1;
	var D = date.getDate();
	var H = date.getHours();
	var m = date.getMinutes();
	var s = date.getSeconds();

	M = M >= 10 ? M : '0' + M
	D = D >= 10 ? D : '0' + D
	H = H >= 10 ? H : '0' + H
	m = m >= 10 ? m : '0' + m
	s = s >= 10 ? s : '0' + s
	if (type == 1) return H + ":" + m
	else if (type == 2) return Y + "." + M + "." + D + ' ' + H + ":" + m
	else if (type == 3) return M + "-" + D
	else if (type == 4) return M + "-" + D + ' ' + H + ":" + m
	else if (type == 5) return Y + "." + M + "." + D
	else if (type == 6) return M + "." + D
	else if (type == 7) return Y + "-" + M 
	else if (type == 8) return Y + "/" + M + "/" + D + ' ' + H + ":" + m + ':' + s
	else if (type == 9) return Y + "/" + M + "/" + D
	else if (type == 10) return  H + ":" + m + ':' + s
	else if (type == 11) return Y + "." + M + "." + D + ' ' + H + ":" + m + ':' + s
	else if (type == 12) return Y + "-" + M + "-" + D 

}
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');;
